<template>
  <b-card>
    <header-details
      @on:cancel-edit="$router.push({name: 'duplicate'})"
    />
    <b-row>
      <b-col v-if="getSelectedCalendar">
        <div class="d-flex">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.park-model') }}:</span>
          <d-text-field :value="getSelectedCalendar.club.name" class-name="bg-white" readonly size="sm"/>

          <span class="modal-title font-weight-bold mt-1 mr-2 ml-5">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
        <div class="d-flex mt-2">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.used-cal') }}:</span>
          <d-text-field
            :value="getSelectedCalendar.name"
            readonly
            size="sm"
            class-name="bg-white"
            class="cursor-disabled"
          />
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-flex">
          <span class="modal-title font-weight-bold mr-3 mt-1">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-2">
      <b-col v-if="getSelectedCalendar" cols="7">
        <fieldset>
          <legend class="d-flex justify-content-center font-weight-bold h6">
            {{
              getSelectedCalendar.club.name
            }}
          </legend>
          <permanent-form
            v-if="getSelectedCalendar.dates.length === 0"
            :calendar="getSelectedCalendar"
            icon="icofont icofont-download-alt text-danger"
          />
          <holidays-form
            v-else
            :calendar="getSelectedCalendar"
            icon="icofont icofont-download-alt text-danger"
          />
          <div class="d-flex justify-content-center">
            <d-button
              :text="$t('admin.duplicate.set-data')"
              class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
              @on:button-click="onDuplicateAll"
            />
          </div>
        </fieldset>
      </b-col>
      <b-col cols="12">
        <div style="height: 40px" class="w-100 bg-light-red d-flex justify-content-center align-items-center">
          {{ $t('admin.duplicate.cal-to-edit') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="(item, index) of groupedSelectedCalendarsByClub"
        :key="'checked-' + index"
        cols="6"
        class="mt-3"
      >
        <fieldset>
          <legend style="width: auto" class="d-flex font-weight-bold h6">
            <span class="ml-2 mr-2">{{ item.club.name }}</span>
          </legend>
          <template
            v-for="(calendar, index) of item.categories"
          >
            <permanent-form
              v-if="calendarType === 'permanent'"
              :key="'index' + 'category' + index"
              :calendar="calendar"
              :has-border="index < item.categories.length - 1"
              :icon="'icofont icofont-upload-alt text-danger'"
            />

            <holidays-form
              v-else
              :key="'index' + 'category' + index"
              :calendar="calendar"
              :has-border="index < item.categories.length - 1"
              :icon="'icofont icofont-upload-alt text-danger'"
            />
          </template>
        </fieldset>
      </b-col>

      <b-col cols="12" align="middle">
        <d-button
          :text="$t('admin.duplicate.calendar.confirm-go-to-home')"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
          @on:button-click="displayModal(false)"
        />

        <d-button
          :text="$t('admin.duplicate.calendar.confirm-config-blocks')"
          class="d-btn-sm d-btn btn ml-2 d-btn-warning font-text-title mt-3"
          @on:button-click="displayModal(true)"
        />
      </b-col>
      <progress-bar-modal
        modalId="identifier-validate"
        :display="display"
        title="general.actions.notification"
        :is-busy="isBusy"
        :count="count"
        :text="$t('admin.duplicate.ask-for-confirmation')"
        :hide="hide"
        @on:cancel="hide = !hide"
        @on:confirm="onValidate"
      />
    </b-row>
  </b-card>
</template>

<script>
import HeaderDetails from "@views/duplicate/HeaderDetails";
import DuplicateShopCategoryForm from "@custom/duplicate/DuplicateShopCategoryForm";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {_put} from "@api/services/httpService";
import ProgressBarModal from "@custom/duplicate/ProgressBarModal";
import PermanentForm from "@views/duplicate/calendar/PermanentForm";
import {cloneData} from "@/utils/form";
import HolidaysForm from "@views/duplicate/calendar/HolidaysForm";
import {UTC_SERVER_DATE_FORMAT} from "@/utils/date";

export default {
  data: () => ({
    hide: false,
    display: false,
    configBlocks: false,
    count: {
      success: 0,
      failed: 0,
      totalCount: 0,
    },
    isBusy: false
  }),
  computed: {
    getSelectedCalendar() {
      return this.$store.getters['calendar/getSelectedCalendarModel'];
    },
    calendarType() {
      return this.$route.params.calendarType;
    },
    getCheckedCalendarList() {
      return this.$store.getters['calendar/getCheckedCalendars'];
    },
    impactedParks() {
      let label = '';
      for (let i = 0; i < this.groupedSelectedCalendarsByClub.length; i++) {
        label += i < this.groupedSelectedCalendarsByClub.length - 1 ? this.groupedSelectedCalendarsByClub[i].club.name + ',' : this.groupedSelectedCalendarsByClub[i].club.name;
      }
      return label;
    },
    groupedSelectedCalendarsByClub() {
      let groupList = [];

      for (const item of this.getCheckedCalendarList) {
        if (groupList.length > 0) {
          const checkIfAdded = groupList.find(el => el.club.id === item.club.id);

          if (isNotUndefinedAndNotNull(checkIfAdded)) {
            checkIfAdded.categories.push(item);
          } else {
            groupList.push({club: item.club, categories: [item]});
          }
        } else {
          groupList.push({club: item.club, categories: [item]});
        }
      }

      return groupList.sort((b, a) => a.categories.length - b.categories.length);
    }
  },
  components: {HolidaysForm, PermanentForm, ProgressBarModal, HeaderDetails},
  methods: {
    displayModal(configBlocks = false) {
      this.count.success = 0;
      this.count.failed = 0;
      this.configBlocks = configBlocks;
      this.count.totalCount = this.getCheckedCalendarList.length;
      this.display = !this.display;
    },
    async onValidate() {
      if (this.isBusy === false) {
        this.isBusy = true;
        this.count = {success: 0, failed: 0, totalCount: this.getCheckedCalendarList.length}
        const groupList = [];

        for (let g = 0; g < this.groupedSelectedCalendarsByClub.length; g++) {
          const group = this.groupedSelectedCalendarsByClub[g];

          for (let i = 0; i < group.categories.length; i++) {
            const calendar = group.categories[i];

            await _put(calendar['@id'], {
              name: calendar.name,
              fromDate: this.$moment(calendar.fromDate).format(UTC_SERVER_DATE_FORMAT),
              toDate: this.$moment(calendar.toDate).format(UTC_SERVER_DATE_FORMAT),
              daysOfTheWeek: calendar.daysOfTheWeek,
              dates: calendar.dates
            })
              .then((response) => {
                const blocks = response.data.blocks;
                blocks.sort((a, b) => (this.$moment(a.startAt) > this.$moment(b.startAt)) ? 1 : ((this.$moment(b.startAt) > this.$moment(a.startAt)) ? -1 : 0))

                for (let block of blocks) {
                  Object.assign(block, {actions: ['delete']});
                  Object.assign(block, {update: true});
                }

                if (groupList.length > 0) {
                  const checkIfAdded = groupList.find(el => el.calendar.id === response.data.id);

                  if (isNotUndefinedAndNotNull(checkIfAdded)) {
                    checkIfAdded.categories.push(...blocks);
                  } else {
                    groupList.push({
                      club: response.data.club,
                      calendar: response.data,
                      categories: [...blocks]
                    });
                  }
                } else {
                  groupList.push({
                    club: response.data.club,
                    calendar: response.data,
                    categories: [...blocks]
                  });
                }
                this.$store.commit('block/setBlockList', groupList);
                this.count.success++;
              })
              .catch((err) => {
                this.count.failed++;
              })
              .finally(() => {
                if (this.count.success + this.count.failed === this.count.totalCount) {
                  this.isBusy = false;
                  if (this.configBlocks === false) {
                    setTimeout(() => {
                      this.$router.push({name: 'duplicate'})
                    }, 800);
                  } else {
                    setTimeout(() => {
                      this.$router.push({name: 'duplicate_calendar_blocks'})
                    }, 800);
                  }
                }
              })
            ;
          }
        }
      }
    },
    onDuplicateAll() {
      const calendar = cloneData(this.getSelectedCalendar);
      delete calendar.club;
      delete calendar['@id'];

      this.$store.commit('calendar/updateCalendarCheckedList', calendar);
    }
  },
}
</script>
<style scoped lang="scss">

.bg-light-red {
  background-color: #FF0101;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 294px);
  left: 100px;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}

@import "@lazy/_b-card.scss";
</style>
